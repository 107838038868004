import * as z from 'zod';

const MIN_AGE = 15;



// export const phoneSchema = z.object({
//     // phone_code: z.string().nonempty({ message: "Country code is required" }),
//     phone_number: z.string().min(9, { message: "Phone number must be at least 9 digits" }).regex(/^\d+$/, { message: "Phone number can only contain digits" }),
// });


// export const phoneSchema = z.object({
//     phone_number: z.string()
//         .length(9, { message: "Phone number must be valid and 9 digits long." })
//         .regex(/^\d+$/, { message: "Phone number must be valid and 9 digits long." }),
// });

export const getPhoneSchema = (digits: number) =>
    z.object({
        phone_number: z
            .string()
            .length(digits, { message: `Phone number must be ${digits} digits long.` })
            .regex(/^\d+$/, { message: "Phone number must only contain digits." }),
    });


export const otpSchema = z.object({
    otp: z.string().min(4, { message: 'Please enter a valid otp' }).max(4),
});

export const signupSchema = z.object({
    phone_number: z.string()
        .min(9, 'Phone number must be at least 9 characters long')
        .max(15, 'Phone number must be at most 15 characters long'),
    national_id: z.string()
        .min(10, 'National ID Number is required')
        .max(15, 'National ID Number must be at most 15 characters long')
        .refine((val) => /^[a-zA-Z0-9]*$/.test(val), 'National ID Number must be alphanumeric'),
    first_name: z.string()
        .min(1, 'First name is required')
        .max(50, 'First name must be at most 50 characters long'),
    last_name: z.string()
        .min(1, 'Last name is required')
        .max(50, 'Last name must be at most 50 characters long'),

    // gender: z.enum(['Male', 'Female', 'Other'], {
    //     required_error: 'Gender is required',
    // }),

    gender: z.string()
        .min(1, 'Gender is required')
        .max(20, 'Gender is required'),


    city: z.string()
        .min(1, 'City is required')
        .max(100, 'City is required'),
    date_of_birth: z.string().refine((value) => {
        const today = new Date();
        const dob = new Date(value);
        return dob < today;
    }, {
        message: 'You must be at least 15 years old',
    }),
    // date_of_birth: z.string().refine((value) => {
    //     if (!value) return true; // Allow empty strings
    //     const today = new Date();
    //     const dob = new Date(value);
    //     const age = today.getFullYear() - dob.getFullYear();
    //     const monthDiff = today.getMonth() - dob.getMonth();

    //     return age > MIN_AGE || (age === MIN_AGE && monthDiff >= 0);
    // }, {
    //     message: 'You must be at least 18 years old',
    // }),
    email: z.string()
        .email('Invalid email address')
        .max(100, 'Email must be at most 100 characters long'),
});


