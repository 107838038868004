import axios, { AxiosError, AxiosResponse } from 'axios';
import ENV_VARIABLES from './env.variable';

const AUTHENTICATION_AXIOS = axios.create({
    timeout: 100000,
    baseURL: `${ENV_VARIABLES.AUTH_API_BASE_URL}`,
    headers: {
        'Content-Type': 'application/json'
    }
    
});

const PATIENT_API_AXIOS = axios.create({
    timeout: 100000,
    baseURL: `${ENV_VARIABLES.API_BASE_URL}`,
    headers: {
        'Content-Type': 'application/json'
    }
});

PATIENT_API_AXIOS.interceptors.request.use(
    function (config) {
        // if (config?.headers) config.headers.Authorization = `Bearer droptokenHere`;
        return config;
    },
    (error) => requestError(error)
);

PATIENT_API_AXIOS.interceptors.response.use(
    (config) => responseConfig(config),
    (error) => responseError(error)
);



// response interceptor
const responseConfig = (config: AxiosResponse) => {
    return config;
};

const responseError = async (error: AxiosError) => {
   return Promise.reject(error);
};

const requestError = async (error: AxiosError) => {
    return Promise.reject(error);
};

const API_CONSTANTS = {
    AUTHENTICATION_AXIOS,
    PATIENT_API_AXIOS
};
export default API_CONSTANTS;
