import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useAppDispatch } from './redux/store/hooks';
import { getPatientHomeData } from './pages/Home/action';
import SignUp from './pages/Login-SignUp/signUp';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import { getInsuranceList, getSpecializationList } from './actions';

const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login-SignUp'));
const ConsultDoctor = lazy(() => import('./pages/Consult_Doctor'));
const MyAppointments = lazy(() => import('./pages/My_Appoinments'));
const MyOrders = lazy(() => import('./pages/My_Orders'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const Profile = lazy(() => import('./pages/Profile'));

const App: React.FC = () => {

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getPatientHomeData())
    dispatch(getInsuranceList());
    dispatch(getSpecializationList());
  }, [])

 return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/consult-doctor" element={<ConsultDoctor />} />
          <Route path="/consult-doctor/spec/:id" element={<ConsultDoctor />} />
          <Route path="/my-appointments" element={<MyAppointments />} />
          <Route path="/my-orders" element={<MyOrders />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/account" element={<Profile />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
