import AsyncSelect from 'react-select/async';
import { ICity } from '../../redux/slices/citySlice';
import END_POINTS from '../../utils/api/endpoints';
import API_CONSTANTS from '../../utils/api/api.configuration';

const fetchCityFromAPI = async (inputValue: string) => {
    const response = await API_CONSTANTS.PATIENT_API_AXIOS.get(END_POINTS.CITY, {
        params: {
            limit: 10,
            country_code: 'ke',
            ...(inputValue ? { search: inputValue } : {})
        }
    });
    const responseCity: ICity[] = response?.data?.body
    return responseCity.map((item: any) => ({
        label: item.name,
        value: item.id,
        ...item
    }));
};

type IProp = {
    handleCityChange: (value: any) => void;
    value?: any;
}
function CityAutocomplete({ handleCityChange, value }: IProp) {
    const promiseOptions = (inputValue: string) => {
        return fetchCityFromAPI(inputValue);
    }
    return (
        <AsyncSelect loadOptions={promiseOptions} placeholder='Search City' defaultOptions isClearable onChange={handleCityChange} value={value} />
    )
}

export default CityAutocomplete