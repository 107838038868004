import { combineReducers } from 'redux';
import authReducer from "../slices/authSlice"
import countryReducer from "../slices/countrySlice"
import patientHomeReducer from "../slices/patientHomeSlice"
import globalLoaderReducer from "../slices/globalLoaderSlice"
import doctorListReducer from "../slices/doctorListSlice"
import insuranceListReducer from '../slices/insuranceListSlice';
import specializationReducer from '../slices/specializationSlice';
import userRatingReducer from '../slices/userRatingSlice';
import cityListReducer from "../slices/citySlice"

const rootReducer = combineReducers({
    auth: authReducer,
    patientHome: patientHomeReducer,
    globalLoader: globalLoaderReducer,
    doctorList: doctorListReducer,
    insuranceList: insuranceListReducer,
    countryList: countryReducer,
    specializationList: specializationReducer,
    userRatingList: userRatingReducer,
    cityList: cityListReducer,
});

export default rootReducer;