import * as React from "react";

import { cn } from "../lib/utils";
import { FieldStateError } from "../utils/types";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  fieldState?: FieldStateError;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, fieldState, type, value, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          `flex h-12 w-full border border-[#CBCAD7] rounded-md text-base bg-backgroundInput px-3 py-2 ring-offset-background file:border-0 file:bg-transparent font-medium placeholder:text-[#333333B2] placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 ${
            fieldState?.error?.message
              ? "focus-visible:ring-danger"
              : "focus-visible:ring-[#CBCAD7]"
          } disabled:cursor-not-allowed disabled:opacity-50`,
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);

Input.displayName = "Input";

export { Input };
