import { ICity, setCityList } from "../redux/slices/citySlice";
import { setCountryList } from "../redux/slices/countrySlice";
import { setInsuranceList } from "../redux/slices/insuranceListSlice";
import { setSpecializationList } from "../redux/slices/specializationSlice";
import { AppDispatch } from "../redux/store/store";
import { showAlert } from "../utils/alert";
import API_CONSTANTS from "../utils/api/api.configuration";
import END_POINTS from "../utils/api/endpoints";

export const getInsuranceList = () => {
    return (dispatch: AppDispatch) => {
        API_CONSTANTS.PATIENT_API_AXIOS.get(END_POINTS.PATIENT_HOME,{params:{
            is_insurance_company_list:1,
            }})
            .then((res) => {
                dispatch(setInsuranceList(res?.data?.body))
              })
            .catch((error) => {
                const { response, message } = error;
                showAlert(2, message)
                
            });
    };
};
export const getSpecializationList = () => {
    return (dispatch: AppDispatch) => {
        API_CONSTANTS.PATIENT_API_AXIOS.get(END_POINTS.PATIENT_HOME,{params:{
            is_specialization_list:1,
            }})
            .then((res) => {
                dispatch(setSpecializationList(res?.data?.body))
              })
            .catch((error) => {
                const { response, message } = error;
                showAlert(2, message)
                
            });
    };
};


export const getCountryList = () => {
    return (dispatch: AppDispatch) => {
        API_CONSTANTS.PATIENT_API_AXIOS.get(END_POINTS.PATIENT_HOME,{params:{
            is_countries_list:1,
            }})
            .then((res) => {
               dispatch(setCountryList(res?.data?.body))
              })
            .catch((error) => {
                const { response, message } = error;
                showAlert(2, message)
                
            });
    };
};


export const getCityList = (search:string="", callback?:(param:ICity[])=>void) => {
    return (dispatch: AppDispatch) => {
        API_CONSTANTS.PATIENT_API_AXIOS.get(END_POINTS.CITY,{params:{
            limit:10,
            country_code:'ke',
            ...(search?{search}:{})
            }})
            .then((res) => {
                dispatch(setCityList(res?.data?.body))
              })
            .catch((error) => {
                const { response, message } = error;
                showAlert(2, message)
                
            });
    };
};