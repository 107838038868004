import ENV_VARIABLES from "./api/env.variable";

export const getImage = (
    primarySource: string | undefined,
    fallbackSource: string,
  ): string => {
    const ourImage = (primarySource && ENV_VARIABLES.IMAGE_BASE_URL + primarySource) || fallbackSource;
    return ourImage;
  };



  export const getPhoneId = () => {
    // if (getPhoneIdFromLS()) {
    //   return getPhoneIdFromLS();
    // } else {
      let phoneId = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        (c) => {
          const r = (Math.random() * 16) | 0;
          const v = c === "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
      // localStorage.setItem("phone_id", phoneId);
  
      return phoneId;
    // }
  };