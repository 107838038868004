import { createSlice } from '@reduxjs/toolkit';
import { IDoctor, Specialization } from '../../utils/types';

interface InitialStateType {
    type: number;
    branches: any[];
    hospital: any[];
    doctors: IDoctor[];
    insurance_compnanies: any[];
    banner_data: any;
    contact_us_emails: any[];
    about_us_texts: string;
    body_data: any;
    specialization_data: Specialization[];
    count_events_pending_action: number
}
const initialState: {patientHomeData:InitialStateType;isLoading:boolean} = {
    patientHomeData:{type: 1,
    branches: [],
    hospital: [],
    doctors: [],
    insurance_compnanies: [],
    banner_data: {},
    contact_us_emails: [],
    about_us_texts: '',
    body_data: {},
    specialization_data: [],
    count_events_pending_action: 0},
    isLoading:false
};

const patientHomeSlice = createSlice({
    name: 'patient-home',
    initialState,
    reducers: {
        setPatientHomeData(state, action) {
            return { ...state, ...action.payload };
        },
        resetPatientHomeData(state) {
            state = initialState;
        }
    }
});

export const { setPatientHomeData, resetPatientHomeData } = patientHomeSlice.actions;
export default patientHomeSlice.reducer;
