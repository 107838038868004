import ExpertsBanner from "../assets/images/experts-banner.png";
import ExpertsCards from "../assets/images/experts-cards.png";
import Eye from "../assets/icons/eye.svg";
import Star from "../assets/icons/star.svg";
import Info from "../assets/icons/info.svg";
import HeroImg from "../assets/images/hero-banner.png";
import Location from "../assets/icons/location.svg";
import Mail from "../assets/icons/mail.svg";
import Instagram from "../assets/icons/instagram.svg";
import Facebook from "../assets/icons/facebook.svg";
import Twitter from "../assets/icons/twitter.svg";
import LiviaFooter from "../assets/images/livia-footer.svg";
import Placeholder from "../assets/images/placeholder.svg";
import ArrowRightWhite from "../assets/icons/arrow-right-white.svg";
import BookAppointment from "../assets/images/book-appointment.png";
import LoginSignUp from "../assets/images/login-signup.svg";
import ExpertsTeamAvatar from "../assets/images/experts-team-avatar.png";
import OnlineStatus from "../assets/icons/online.svg";
import Dollar from "../assets/icons/dollar.svg";
import DoctorPlaceholder from "../assets/images/doctor-placeholder.png";
import LogoLight from "../assets/images/logo-light.svg";
import LogoDark from "../assets/images/logo-dark.svg";
import LoginBanner from "../assets/images/login-banner.png";
import FilterApplied from "../assets/icons/FilterAppliedIcon.png";

const images = {
  EXPERTSBANNER: ExpertsBanner,
  EXPERTSCARDS: ExpertsCards,
  EYE: Eye,
  STAR: Star,
  INFO: Info,
  HEROIMG: HeroImg,
  LOCATION: Location,
  MAIL: Mail,
  INSTAGRAM: Instagram,
  FACEBOOK: Facebook,
  TWITTER: Twitter,
  LIVIAFOOTER: LiviaFooter,
  PLACEHOLDER: Placeholder,
  ARROWRIGHTWHITE: ArrowRightWhite,
  BOOKANAPPOINTMENT: BookAppointment,
  LOGINSIGNUP: LoginSignUp,
  EXPERTSTEAMAVATAR: ExpertsTeamAvatar,
  ONLINE: OnlineStatus,
  DOLLAR: Dollar,
  DOCTORPLACEHOLDER: DoctorPlaceholder,
  LOGOLIGHT: LogoLight,
  LOGODARK: LogoDark,
  LOGINBANNER: LoginBanner,
  FILTERAPPLIED: FilterApplied,
};
export default images;
