import React from "react";
import images from "../utils/images";

interface AuthLayoutProps {
  children: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  return (
    <div className="flex h-[100vh] p-5">
      <div className="max-w-2/5 h-full relative inline-block">
        <img
          src={images.LOGINBANNER}
          alt="sample drop"
          className="h-full"
          loading="lazy"
        />
        <div className="absolute top-0 left-0 p-5">
          <button className="bg-seaGreen rounded-[70px] text-white font-semibold text-[14px] flex items-center justify-center gap-1.5 w-[90px] h-[32px]">
            <img
              src={images.ARROWRIGHTWHITE}
              alt="arrow"
              className="rotate-180 w-4"
            />
            Back
          </button>
          <h2 className="text-white font-medium text-3xl mb-2 mt-8">
            Safaricom connects you to care, right from home
          </h2>
          <p className="text=[#FFFFFFCC] text-base font-normal italic">
            “Safaricom brings trusted doctors to you, ensuring care and comfort
            right from home.”
          </p>
        </div>
      </div>

      <main className="w-3/5 h-full flex items-center justify-center">
        <div>{children}</div>
      </main>
    </div>
  );
};

export default AuthLayout;
