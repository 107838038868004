import { setPatientHomeData } from "../../redux/slices/patientHomeSlice";
import { setUserRating } from "../../redux/slices/userRatingSlice";
import { AppDispatch } from "../../redux/store/store"
import { showAlert } from "../../utils/alert";
import API_CONSTANTS from "../../utils/api/api.configuration";
import END_POINTS from "../../utils/api/endpoints";

export const getPatientHomeData = () => {
    return (dispatch: AppDispatch) => {
        dispatch(setPatientHomeData({ isLoading:true}))
        API_CONSTANTS.PATIENT_API_AXIOS.get(END_POINTS.PATIENT_HOME)
            .then((res) => {
                dispatch(setPatientHomeData({patientHomeData:res?.data?.body, isLoading:false}))
              })
            .catch((error) => {
                const { message } = error;
                dispatch(setPatientHomeData({ isLoading:false}))
                showAlert(2, message)
                
            });
    };
};

export const getUserRatingData = () => {
    return (dispatch: AppDispatch) => {
        dispatch(setUserRating({ isUserRatingLoading:true}))
        API_CONSTANTS.PATIENT_API_AXIOS.get(END_POINTS.USER_RATING,{params:{
            limit:10,
            offset:0,
            fromPatientPortal:1
        }})
            .then((res) => {
                dispatch(setUserRating({userRatingList:res?.data?.body, isUserRatingLoading:false}))
              })
            .catch((error) => {
                const { message } = error;
                dispatch(setUserRating({userRatingList:[], isUserRatingLoading:false}))
                showAlert(2, message)
                
            });
    };
};